.advertisement {
    display: flex;
    height: 80%;
    width: 80%;

    .logo {
        height: 60px;
    }

    .modal-content {
        height: 100%;
    }

    .card-deck {
        margin: -0.5rem !important;

        .card {
            flex: none;
            width: 230px;

            .badge {
                border: 2px solid #ffffff;
                font-size: 2rem;
                padding: 0.5rem;
                position: absolute;
                right: -0.5rem;
                top: -0.5rem;
                z-index: 1;
            }

            img,
            .card-img-overlay {
                border-radius: 0.25rem 0.25rem 0 0;
                overflow: hidden;
            }

            .card-img-overlay {
                align-items: flex-end;
                background-color: rgba(0, 0, 0, 0.6);
                display: flex;

                .card-title {
                    color: #ffffff;
                    font-size: 1.5rem;
                    margin: 0;
                }
            }

            .card-footer {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    [data-key='configuration'] {
        .card {
            width: 280px;
        }
    }
}
