.register,
.login,
.forgot {
    > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .progress {
        position: absolute;
    }
    .row {
        margin: 0;
    }
}

.social-login {
    border-bottom: 1px solid $gray-300;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
        border-radius: 0.25rem;
        bottom: -0.75rem;
        background-color: #ffffff;
        color: $gray;
        content: attr(data-or);
        line-height: 1.5rem;
        padding: 0 1rem;
        position: absolute;
    }

    .social-button {
        flex-grow: 1;
        font-size: 1.5rem;
        padding: 0 0.5rem;
        &:not(:first-child) {
            margin-left: 0.5rem;
        }
    }
}

.grecaptcha-badge {
    position: static !important;
    top: auto;
    bottom: auto !important;
    right: auto !important;
    box-shadow: none !important;
    overflow: hidden;
    border-radius: 6px;
    transform-origin: left top;
    transform: scale(0.6);
}
