.myaccount {
    display: flex;
    height: 80%;
    width: 80%;
    .avatar-wrapper {
        border: 2px solid #ffffff;
        border-radius: 0.5rem;
        box-shadow: 0 0 0 2px $secondary;
        overflow: hidden;
        padding: 0;
        img {
            border-radius: 0.5rem;
            overflow: hidden;
            transition: opacity 0.3s ease;
        }
        &:hover {
            img {
                opacity: 0.6;
            }
        }
    }
    .modal-content {
        height: 100%;
    }
    .grid {
        column-gap: 1rem;
        display: grid;
        grid-template-columns: 50% 50%;
        margin-right: 1rem;
    }

    .limits {
        .ant-progress {
            .ant-progress-circle-trail {
                stroke: $gray-200;
            }
            &.ant-progress-status-success {
                .ant-progress-circle-path {
                    stroke: $danger;
                }
                .ant-progress-text {
                    color: $danger;
                }
            }
        }
    }
}
