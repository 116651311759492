.addons-modal {
    .modal-body {
        overflow: auto;
    }
    .addons {
        align-items: center;
        display: grid;
        gap: 1rem;
        //grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        grid-template-columns: repeat(auto-fit, minmax(160px, 200px));

        .addon {
            > .card {
                max-height: 100%;
                width: 100%;

                .card-title {
                    font-weight: bold;
                    margin: 0;
                }

                .card-footer {
                    background-color: transparent;
                    border: none;
                    text-align: right;
                    padding: 0 1rem 1rem;

                    button {
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        position: relative;
                        > i {
                            font-size: 1.8rem;
                            margin: -1rem -0.5rem;
                        }
                        span {
                            flex-grow: 1;
                        }
                    }
                }

                .placeholder {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    &:before {
                        content: '';
                        display: block;
                        padding-top: 154.74%;
                    }
                    img {
                        position: absolute;
                        width: 50%;
                    }
                }
            }

            &[data-loading='true'] {
                button {
                    width: 100%;
                    height: 38px;
                    border: none;
                }

                .card-title {
                    width: 100%;
                    height: 22px;
                }

                button,
                .card-title,
                .placeholder {
                    animation-duration: 1s;
                    animation-fill-mode: forwards;
                    animation-iteration-count: infinite;
                    animation-name: placeholderSkeleton;
                    animation-timing-function: linear;
                    background: #ebebeb;
                    background-image: linear-gradient(
                        to right,
                        #ebebeb 0%,
                        #e0e0e0 40%,
                        #ebebeb 100%
                    );
                    background-repeat: no-repeat;
                    background-size: 200px 320px;
                    position: relative;
                }
            }
        }
    }
}

@media (min-width: 600px) {
    :root {
        --results-columns: 3;
    }
}

@media (min-width: 800px) {
    :root {
        --results-columns: 4;
    }
}

@media (min-width: 1000px) {
    :root {
        --results-columns: 5;
    }
}

@media (min-width: 1200px) {
    :root {
        --results-columns: 6;
    }
}
