// -----------------------------------------------------------------------------
// This file contains all styles related to the json-editor component.
// -----------------------------------------------------------------------------

.json-node-wrapper {
    font-family: consolas, monaco, 'lucida console', 'courier new', courier, monospace, sans-serif;
    line-height: 1.5rem;
    margin: 0;
    position: relative;

    &[data-mode='sort'] {
        .item-actions,
        .item-button,
        .json-node-key,
        .json-node-dots,
        .json-node-wrapper-symbol,
        .json-node-item,
        .json-node-new-item,
        .json-node-expand-button,
        [type*='menu-'] {
            display: none !important;
        }

        .json-node-wrapper {
            align-items: flex-start;
            box-shadow: none !important;
            min-height: 2rem;
            width: 100%;

            &:after,
            &:before {
                display: none !important;
            }
        }

        .json-node-item {
            margin: 0 0 1px;
            padding: 0.25rem 0.5rem;
            border: 2px solid transparent;
            box-shadow: 0 0 0 1px #dddddd;
            background-color: #ffffff;

            &[data-key='content'],
            &[data-key='items'] {
                display: flex !important;
                box-shadow: none;
                width: 100%;

                [data-component-id],
                [data-key='content'],
                [data-key='items'] {
                    display: flex !important;
                }
            }

            &.hover {
                box-shadow: 0 0 0 2px $primary;
                z-index: 1;
            }
            &.sortable-chosen {
                background-color: $primary;
                color: #ffffff;
            }

            > .json-node-value {
                margin: 0;

                &[data-type='object'] {
                    > .json-node-type {
                        color: inherit !important;
                        display: block !important;
                    }

                    > .json-node-wrapper {
                        margin: 0 1.25rem;
                        width: calc(100% - 1.25rem);
                        padding: 0.5rem;

                        > .json-node-item {
                            padding: 0;
                            border: none;
                        }
                    }
                }
            }
        }
    }

    .json-node-dots,
    .json-node-type,
    .json-node-wrapper-symbol {
        cursor: default;
        position: relative;
        color: $gray-800;
        line-height: 26px;
        user-select: none;
    }

    .json-node-type {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .json-node-wrapper {
        box-shadow: -1px 0 0 rgba($secondary, 0.1);
    }

    > .json-node-wrapper {
        box-shadow: none;
    }

    .json-node-item {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 0 0.25rem 1.5rem;
        max-width: 100%;

        &.line-through {
            [contenteditable],
            .json-node-type {
                &:before {
                    content: '';
                    left: 0;
                    right: 0;
                    position: absolute;
                    border-top: 2px solid $danger;
                    margin: 13px 0;
                    z-index: 1;
                }
            }
        }

        &[data-content='true'] {
            order: 2;
        }

        &.active,
        &.hover {
            > .json-node-expand-button {
                color: $primary;
            }
            > .json-node-value {
                > .json-node-type,
                > .json-node-wrapper-symbol {
                    color: $primary;
                }
                > .json-node-wrapper {
                    box-shadow: -1px 0 0 $primary;
                    &:before,
                    &:after {
                        content: '';
                        border-top: 1px solid $primary;
                        box-shadow: -1px 0 0 $primary;
                        position: absolute;
                        left: 0;
                        width: 10px;
                    }
                    &:before {
                        top: 0;
                    }
                    &:after {
                        bottom: 0;
                    }
                }
            }
        }

        .json-node-expand-button {
            color: $gray-800;
            font-size: 1.5rem;
            line-height: 1.5rem;
            margin-left: -1.5rem;
            padding: 0;
            position: absolute;
        }

        .json-node-key {
            > [contenteditable] {
                color: $gray-400;
                display: inline-block;
                &[contentEditable] {
                    color: $gray-600;
                    &:empty {
                        border-color: $danger;
                        min-width: 3rem;
                        padding: 0;
                    }
                }
            }
        }

        .json-node-value {
            align-items: flex-start;
            display: flex;
            flex-grow: 1;
            margin-left: 0.25rem;
            width: 0;

            > a {
                border: 1px solid #ddd;
                border-radius: 1rem;
                width: 24px;
                border-color: $gray-400;
                border-radius: 3rem;
                color: $gray-600;
                order: 1;
                position: relative;
                width: 26px;
                min-width: 0;
                padding: 0;
                border-radius: 3rem;
                -webkit-transition: background 0.3s ease;
                transition: background 0.3s ease;
                justify-content: center;
                display: flex;
                margin: 0 0 0 0.5rem;

                &:hover {
                    background-color: $primary;
                    border-color: $primary;
                    color: #ffffff;
                }
            }

            > .json-node-new-item {
                margin-left: 0.5rem;
            }

            .json-node-value-helper {
                align-self: center;
            }

            .autocomplete-wrapper {
                position: relative;
                max-width: calc(100% - 40px);
            }

            &[data-type='null'] {
                color: $gray-500;
                font-style: italic;
            }
            &[data-type='boolean'],
            &[data-type='number'] {
                color: #0000ff;
            }

            &[data-type='array'],
            &[data-type='object'] {
                > .json-node-wrapper {
                    display: none;
                }

                &[data-expanded='true'] {
                    display: block;
                    width: 100%;

                    > .json-node-wrapper {
                        display: flex;
                        flex-direction: column;
                    }
                    > .item-actions {
                        position: absolute;
                        margin: 0 0 0 2.5rem;
                        z-index: 4;
                    }
                    > .remove-item-button {
                        position: absolute;
                        margin: 0 0 0 0.5rem;
                        z-index: 2;
                    }
                    > .json-node-type {
                        display: none;
                    }
                }
            }

            &[data-type='string'] {
                > .autocomplete-wrapper {
                    > [contentEditable] {
                        color: #008000;
                        display: block;
                        max-height: 26px;
                        overflow: hidden;

                        &:empty {
                            border-color: $danger;
                            min-width: 3rem;
                            padding: 0;
                        }

                        &:focus {
                            max-height: unset;
                        }
                    }
                }
            }
        }

        &.json-node-new-item {
            flex-wrap: wrap;
            order: 1;

            .clone-item-button {
                width: 26px;
                height: 26px;
                padding: 0;
                border: 1px solid $gray-400;
                color: $gray-600;
                margin-left: 0.25rem;
                box-shadow: none !important;

                &:hover {
                    border-color: $primary;
                    color: #ffffff;
                }
            }

            > .json-node-wrapper-symbol {
                color: $gray-400;
                width: 100%;
                & ~ .autocomplete-wrapper {
                    margin: 0 0 0.5rem 1.5rem;
                }
            }
            > .autocomplete-wrapper {
                > [contentEditable] {
                    border-color: $gray-400;
                    position: relative;
                    padding: 0;
                    &:empty {
                        border-color: $gray-400;
                        border-radius: 3rem;
                        color: $gray-600;
                        position: relative;
                        width: 26px;
                        min-width: 0;
                        padding: 0;
                        border-radius: 3rem;
                        transition: background 0.3s ease;
                        &:hover {
                            background-color: $primary;
                            border-color: $primary;
                            color: #ffffff;
                        }
                        &:before {
                            content: '+' !important;
                            border: none !important;
                            cursor: pointer;
                            line-height: 22px;
                            margin: 0 !important;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            z-index: 0 !important;
                        }
                        &:focus {
                            width: auto;
                            min-width: 3rem;
                            border-radius: 3px;
                            background-color: inherit;
                            border-color: $primary;
                            transition: none;
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }
            > .json-node-value > .autocomplete-wrapper {
                > [contentEditable] {
                    border-color: $gray-400;
                    min-width: 0.25rem;
                }
            }
        }

        .item-button {
            margin: 0;
            order: 2;
        }

        &.active,
        &:hover {
            > .json-node-value > .item-actions,
            > .json-node-value > .remove-item-button {
                display: flex;
            }
        }
    }

    .item-actions {
        display: none;
        order: 3;

        .check-item-button {
            > input {
                display: none !important;
            }
            > input {
                & ~ label {
                    margin: 0;
                    .checkbox-blank {
                        display: block !important;
                    }
                    .checkbox-marked {
                        display: none !important;
                    }
                }
                &:checked {
                    & ~ label {
                        .checkbox-blank {
                            display: none !important;
                        }
                        .checkbox-marked {
                            display: block !important;
                        }
                    }
                }
            }
        }
    }

    .item-button {
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        margin: -3px 0 -3px 0.5rem;
        padding: 0;
        width: 23px;
        .mdi {
            display: inline-block;
            font-size: 1.2rem;
            line-height: 1.5rem;
            width: 1.5rem;
        }
        > span {
            margin: 0 0.5rem 0 0.2rem;
            display: none;
        }

        &.remove-item-button {
            display: none;
            font-size: 1rem;
            color: $danger;
            min-width: 30px;
            &:hover {
                color: darken($danger, 20%);

                & ~ .json-node-wrapper {
                    box-shadow: -2px 0 0 $danger;
                    &:before,
                    &:after {
                        content: '';
                        border-top: 2px solid $danger;
                        box-shadow: -2px 0 0 $danger;
                        position: absolute;
                        left: 0;
                        width: 10px;
                    }
                    &:before {
                        top: 0;
                    }
                    &:after {
                        bottom: 0;
                    }
                }
            }
        }
    }

    &:hover {
        > .item-button {
            display: inline-block;
        }
    }

    [contentEditable] {
        position: relative;
        border: 1px solid transparent;
        border-radius: 3px;
        display: inline-block;
        min-height: 26px;
        outline: none;
        padding: 0 0.35rem;

        &[contentEditable='true'] {
            &:hover {
                border-color: $gray;
            }
            &:focus {
                border-color: $primary;
            }
        }
    }
}

.visor-wrapper.visor-on:not(.d-none) {
    + .json-node-wrapper {
        padding-right: 386px !important;
    }
}
