.publish {
    display: flex;
    height: 80%;
    width: 80%;

    .card-deck {
        margin: 1rem -0.5rem 2rem !important;
        .card {
            .card-header {
                background-color: transparent;
                border: none;
                .card-title {
                    margin: 0;
                }
            }
            .card-body {
                padding: 0 0 1rem;
                .icon-success {
                    color: $success;
                    display: inline-block;
                    font-size: 5.5rem;
                    margin: -1rem 0 0rem;
                }
            }
            .card-footer {
                text-align: right;
            }
        }
    }

    .modal-content {
        height: 100%;

        .ant-menu-item {
            img {
                height: 1.2rem;
            }
        }
        .modal-body > .modal-body {
            img {
                height: 64px;
                margin-bottom: 2rem;
            }
        }
    }
}
