// -----------------------------------------------------------------------------
// This file contains all styles related to the color-picker component.
// -----------------------------------------------------------------------------

.color-picker {
    display: flex;
    position: relative;

    .color-picker-swatch {
        background-color: #ffffff;
        border: 1px solid $gray-400;
        border-radius: 0.25rem 0 0 0.25rem;
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        position: relative;
        width: 2rem;

        &:before,
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
        }

        &:before {
            background-image: -moz-linear-gradient(45deg, #ccc 25%, transparent 25%),
                -moz-linear-gradient(-45deg, #ccc 25%, transparent 25%),
                -moz-linear-gradient(45deg, transparent 75%, #ccc 75%),
                -moz-linear-gradient(-45deg, transparent 75%, #ccc 75%);
            background-image: -webkit-gradient(
                    linear,
                    0 100%,
                    100% 0,
                    color-stop(0.25, #ccc),
                    color-stop(0.25, transparent)
                ),
                -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0.25, #ccc), color-stop(0.25, transparent)),
                -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.75, transparent), color-stop(0.75, #ccc)),
                -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0.75, transparent), color-stop(0.75, #ccc));

            -moz-background-size: 10px 10px;
            background-size: 10px 10px;
            -webkit-background-size: 10px 10px; /* override value for shitty webkit */

            background-position: 0 0, 5px 0, 5px -5px, 0px 5px;
        }

        &:after {
            background-color: currentColor;
            border-radius: 0.25rem 0 0 0.25rem;
        }
    }
    > .color-picker-swatch {
        border-radius: 0.25rem;
        height: 1rem;
        margin-right: 0.25rem;
        width: 1rem;
    }

    .color-picker-picker {
        position: fixed;
        z-index: 9;

        .sketch-picker {
            position: relative;
            z-index: 1;
        }

        .color-picker-bg {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 0;
        }
    }
}
