// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.btn {
    white-space: nowrap;

    &.icon {
        background-color: transparent;
        border: none;
        border-radius: 3rem;
        height: 40px;
        padding: 0;
        text-align: center;
        width: 40px;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled).hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
            background-color: rgba(0, 0, 0, 0.15);
        }

        i {
            color: $gray-700;
            font-size: 1.5rem;
        }
    }
}
