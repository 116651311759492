@charset "UTF-8";

// 1. Configuration and helpers
@import 'abstracts/variables', 'abstracts/functions', 'abstracts/keyframes', 'abstracts/mixins';

// 2. Vendors
@import '~antd/dist/antd.css', 'vendors/antd-custom', 'vendors/quill', 'vendors/jsoneditor-custom',
    'vendors/materialdesignicons-custom', 'vendors/react-select-custom', 'vendors/slick-custom',
    '~bootstrap/scss/bootstrap', 'vendors/bootstrap-custom',
    '~cookieconsent/build/cookieconsent.min.css', '~react-intl-tel-input/dist/main.css',
    '~@mdi/font/css/materialdesignicons.min.css';

// 3. Base stuff
@import 'base/base', 'base/fonts', 'base/typography', 'base/helpers';

// 4. Layout-related sections
@import 'layout/alert', 'layout/header', 'layout/footer', 'layout/forms', 'layout/sidebar',
    'layout/visor';

// 5. Components
@import 'components/addon', 'components/application', 'components/autocomplete', 'components/button',
    'components/checkbox', 'components/color-picker', 'components/drop-wrapper',
    'components/input-password', 'components/json-editor', 'components/loader',
    'components/menu-actions', 'components/panel', 'components/placeholder', 'components/preview',
    'components/progress', 'components/resource', 'components/search', 'components/select',
    'components/stripe', 'components/tree';

// 6. Page-specific styles
@import 'pages/advertisement', 'pages/appinfo', 'pages/authenticate', 'pages/bars', 'pages/checkout',
    'pages/dashboard', 'pages/home', 'pages/main', 'pages/maintenance', 'pages/myaccount',
    'pages/not-found', 'pages/premium', 'pages/publish', 'pages/reset-password', 'pages/sources',
    'pages/terms', 'pages/tools', 'pages/upgrade', 'pages/verify-email', 'pages/welcome';

// 7. Themes
@import 'themes/default';
