// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

header {
    &.main-header {
        background-color: #ffffff;
        display: flex;
        height: 60px;
        position: relative;
        z-index: 2;
        .navbar-brand {
            cursor: default;
            height: 60px;
            margin-right: 0.5rem;
            padding: 0.5rem;
            width: 60px;

            img {
                height: 100%;
                width: auto;
                &.icon {
                    border: 3px solid transparent;
                    border-radius: 0.5rem;
                    box-shadow: 0 0 0 2px #ffffff;
                    height: 40px;
                    margin: 0.2rem;
                    width: 40px;
                }
            }

            .btn {
                background: rgba(0, 0, 0, 0.4);
                border-radius: 0.5rem;
                box-shadow: none;
                color: #ffffff;
                height: 40px;
                left: 0;
                margin: 11px;
                opacity: 0;
                position: absolute;
                top: 0;
                width: 40px;
                &:hover {
                    opacity: 1;
                }
            }
        }

        a {
            color: $gray-700;
        }
        .dropdown-divider {
            border-top-color: rgba(0, 0, 0, 0.1);
        }
        .dropdown-item,
        .dropdown-toggle {
            cursor: default;
            font-size: 0.8rem;
            transition: none;

            &[disabled],
            &.disabled {
                color: rgba(0, 0, 0, 0.3);
            }

            &:hover {
                background-color: lighten($bg, 75%);
            }
        }
        .dropdown-item {
            display: flex;
            .checkbox {
                background-color: transparent !important;
                position: absolute;
                margin-left: -1.2rem;
                i {
                    background-color: transparent !important;
                    border: none;
                    &:before {
                        font-size: 0.9rem !important;
                        font-weight: normal !important;
                    }
                }

                :checked {
                    + i {
                        color: $gray-700 !important;
                    }
                }
            }
        }
        .dropdown-menu {
            background-color: lighten($bg, 78%);
            border: none;
            box-shadow: 0 0.5rem 1rem rgba(lighten($bg, 50%), 0.4);
            margin: 0;

            .dropdown-menu {
                transform: translate3d(0, -0.5rem, 0) !important;
                left: 100% !important;
                &.dropdown-menu-right {
                    left: -100% !important;
                }
            }

            &.dropdown-menu-right {
                .dropdown-toggle {
                    &:before {
                        left: 0.5rem;
                        position: absolute;
                    }
                }
            }
        }
        .dropdown-toggle {
            align-items: center;
            color: $gray-900;
            display: flex;
            justify-content: space-between;
            padding: 0.25rem 1.5rem;

            &:after {
                border-top: 0.3em solid transparent;
                border-right: 0;
                border-bottom: 0.3em solid transparent;
                border-left: 0.3em solid;
                margin: 0 -1rem 0 1rem;
            }
        }

        .breadcrumbs {
            color: #ffffff;
            font-size: 1.2rem;
            white-space: nowrap;
            padding: 0.25rem 0.5rem 0;
            font-size: 1.2em;
            cursor: default;

            [contenteditable='true'] {
                cursor: text;
            }
        }

        .main-menu {
            flex-grow: 1;
        }

        .main-menu .nav,
        .shortcuts {
            .preloader {
                img {
                    position: absolute;
                    top: 2px;
                    width: 23px;
                }
            }
            > .dropdown {
                > .dropdown-toggle {
                    border-radius: 0.25rem;
                    color: #ffffff;
                    padding: 0.15rem 0.5rem;
                    position: relative;
                    z-index: 2;

                    &:hover {
                        background-color: rgba(255, 255, 255, 0.25);
                    }
                    &[aria-expanded='true'] {
                        background-color: lighten($bg, 78%);
                        border-radius: 0.25rem 0.25rem 0 0;
                        color: inherit;
                    }

                    &:after {
                        display: none;
                    }
                }
                > .dropdown-menu {
                    z-index: 1;
                }
            }
        }

        .main-menu .nav {
            > .dropdown {
                > .dropdown-menu {
                    border-top-left-radius: 0;
                }
            }
        }

        .shortcuts {
            align-items: center;
            display: flex;
            padding: 0 1rem;

            > * {
                margin-left: 1rem;
            }

            > button {
                align-items: center;
                background-color: transparent !important;
                border: none;
                box-shadow: none !important;
                cursor: default !important;
                display: flex;
                flex-direction: column;
                justify-content: center;
                line-height: 1.2rem;
                padding: 0.25rem 0.5rem;
                &:hover {
                    background-color: rgba(255, 255, 255, 0.25) !important;
                }
                &:active {
                    background-color: rgba(255, 255, 255, 0.5) !important;
                }

                > i {
                    font-size: 1.2rem;
                }
                > span {
                    font-size: 0.7rem;
                    text-transform: uppercase;
                }
            }

            .account {
                > .dropdown-toggle {
                    border-radius: 0.75rem !important;
                    padding: 0.1rem;
                    &[aria-expanded='true'] {
                        background-color: transparent;
                        border-radius: 0.75rem !important;
                    }
                }
                .avatar {
                    border: 3px solid transparent;
                    border-radius: 0.5rem;
                    box-shadow: 0 0 0 2px #ffffff;
                    height: 40px;
                    margin: 0.2rem;
                    width: 40px;
                }
            }
        }
    }
}

.timer-modal {
    .card-body {
        padding: 0 0 1rem;
        .card-title {
            margin-bottom: 0.5rem;
            line-height: 1.2;
            strong {
                font-size: 80%;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
    }
}

[data-mobile='true'] {
    .search-box {
        z-index: 0 !important;
    }
}

@media (min-width: 576px) {
    header {
        .navbar-nav {
            width: 100%;
            justify-content: flex-end;

            .nav-item {
                display: flex;
                align-items: center;
            }

            .dropdown {
                width: 100%;
                justify-content: flex-end;

                .nav-link {
                    &[aria-expanded='true'] {
                        img {
                            box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.3);
                        }
                    }
                }
            }
        }
    }
}
