.tree {
    padding-bottom: 0.5rem;

    .tree-wrapper {
        padding: 0.5rem 0;
    }

    .tree-item {
        align-items: center;
        display: flex;
        justify-content: space-between;
        position: relative;

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);

            .tree-item-actions {
                display: flex;
            }
        }

        &.tree-item--current {
            background: $primary;

            i,
            span {
                color: #ffffff !important;
            }
        }

        &.tree-item-folder,
        &.tree-parent {
            background-color: transparent;
            .tree-item-actions {
                display: flex;
            }
        }
        &.tree-parent {
            .tree-item-body {
                i {
                    font-size: 1.5rem;
                    margin-left: -0.5rem;
                }
            }
        }

        i {
            font-size: 1rem;
        }

        .tree-item-body {
            align-items: center;
            color: $gray-600;
            display: flex;
            flex-grow: 1;
            line-height: 30px;
            max-width: 100%;
            padding: 0;
            position: relative;
            z-index: 1;

            span {
                white-space: nowrap;
                overflow: hidden;
                padding: 0 0.5rem;
                text-overflow: ellipsis;
            }
        }

        .tree-item-body {
            flex-grow: 1;
            width: 0;
        }

        .tree-item-actions {
            display: none;
            position: relative;
            z-index: 2;

            > button {
                background-color: transparent;
                margin-right: 0.25rem;
                padding: 0;
                height: 30px;
                width: 30px;
                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
}
