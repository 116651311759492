// -----------------------------------------------------------------------------
// This file contains all styles related to the color-picker component.
// -----------------------------------------------------------------------------

.autocomplete-wrapper {
    display: inline-block;

    &[data-ui='dropdown'] {
        align-items: center;
        cursor: pointer;
        caret-color: transparent;
        display: flex;

        [contenteditable]:focus {
            border: none;
        }

        .autocomplete {
            .autocomplete-suggestions {
                border-radius: 0.5rem;
            }
        }
    }

    .autocomplete {
        position: absolute;
        z-index: 99;
        overflow-x: hidden;
        overflow-y: auto;

        .autocomplete-suggestions {
            background: #fff;
            border: 1px solid $gray-400;
            list-style: none;
            margin: 0;
            max-height: 400px;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 0;

            &:empty {
                display: none;
            }

            .suggestions-option {
                cursor: default;
                padding: 0.2rem 1rem;

                &.highlighted {
                    background: $gray-200;
                }
            }
        }
    }
}
