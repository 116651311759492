// -----------------------------------------------------------------------------
// This file contains styles that are specific to the checkout page.
// -----------------------------------------------------------------------------

.checkout-plan {
    padding-top: $header-height;
}

.checkout,
.checkout-upgrade {
    #RegisterForm,
    #CustomerForm,
    #StripeForm {
        button,
        .sign-buttons {
            display: none;
        }
    }
    #CustomerForm {
        & + hr {
            display: none;
        }

        .icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            border-radius: 50%;
        }
    }
}

.checkout {
    .payment-summary {
        width: 250px;
    }
}

.fixed-bottom-button {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 9;
    bottom: 0;
    padding: 1rem;
    background-color: #fff;
    border: none;
    border-top: 1px solid #ddd;
}

@media (min-width: 768px) {
    .fixed-bottom-button {
        position: relative;
        background-color: transparent;
        border-top: none;
    }
}
