.panel {
    border: none;
    border-radius: 0;
    font-size: 0.9rem;
    height: calc(100vh - 60px);
    width: 200px;

    .react-resizable-handle-e,
    .react-resizable-handle-w {
        bottom: 0;
        cursor: ew-resize;
        position: absolute;
        top: 0;
        transition: background 0.2s ease;
        width: 3px;
        z-index: 3;

        &:active,
        &:hover {
            background-color: $primary;
        }
    }
    .react-resizable-handle-e {
        right: -2px;
    }
    .react-resizable-handle-w {
        display: none;
        left: -2px;
    }

    ~ .main-content {
        ~ .panel {
            .react-resizable-handle-e {
                display: none;
            }
            .react-resizable-handle-w {
                display: block;
            }
        }
    }

    &[disabled],
    &.disabled {
        display: none;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 10;
        }
    }

    i {
        font-size: 1.2rem;
    }

    input,
    .btn {
        font-size: 0.8rem;
    }
    .dropdown-menu {
        .btn {
            font-size: 1rem;
            &:active,
            &:focus,
            &:hover {
                background-color: lighten($bg, 77%);
                border-radius: 0;
                box-shadow: none !important;
                outline: none;
            }
        }
    }

    [contenteditable] {
        display: block;
        padding: 0.25rem 0.5rem;
        font-weight: 400;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        margin-left: 0.5rem;
        min-width: 50px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        &:focus {
            border-color: $primary;
            box-shadow: 0 0 0 4px rgba($primary, 0.12);
            outline: none;
        }
    }

    .card-header {
        align-items: center;
        border: none;
        border-radius: 0;
        background-color: lighten($bg, 70%);
        display: flex;
        min-height: 40px;
        outline: 1px solid lighten($bg, 73%);
        padding: 0 0.5rem 0 1.25rem;
        white-space: nowrap;
        z-index: 2;

        strong {
            color: lighten($bg, 30%);
            display: block;
            line-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    > .card-body {
        outline: 1px solid lighten($bg, 70%);
    }

    .card-body {
        background-color: lighten($bg, 77%);
        display: flex;
        flex-direction: column;
        overflow: auto;
        padding: 0;
        z-index: 1;

        .card-header {
            background-color: lighten($bg, 75%);
            outline: none;
        }
        .card-body {
            padding: 1rem 1.25rem;
        }

        .list-group {
            border-radius: 0;

            .list-group-item {
                align-items: center;
                background-color: lighten($bg, 77%);
                border-color: lighten($bg, 70%);
                color: $gray-700;
                cursor: pointer;
                display: flex;
                line-height: 38px;
                padding: 0 1rem 0 1.25rem;
                white-space: nowrap;

                &:hover {
                    background-color: rgba(lighten($bg, 60%), 0.1);
                }

                &.active,
                &[data-active='true'] {
                    color: $primary;
                }

                .checkbox {
                    align-items: center;
                    background-color: transparent;
                    display: flex;
                    justify-content: center;
                    margin: 0 0 0 -0.5rem;
                    overflow: visible;
                    input {
                        height: 30px;
                        width: 30px;
                    }
                }

                .icon {
                    margin: 0 0.5rem;
                }

                span {
                    flex-grow: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .btn-group {
            .btn {
                align-items: center;
                background-color: lighten($bg, 75%);
                border-color: lighten($bg, 70%);
                box-shadow: none !important;
                color: $gray-700;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                padding: 0.25rem 0.5rem;
                white-space: nowrap;
                z-index: 0;

                &:hover {
                    background-color: lighten($bg, 76%);
                }

                &.active,
                &[data-active='true'] {
                    border-color: $primary;
                    box-shadow: 0 0 0 1px $primary !important;
                    z-index: 1;
                }

                span {
                    overflow: hidden;
                    width: 100%;
                    text-overflow: ellipsis;
                }
            }
        }

        .drag-item-group {
            border: none;
            &[data-items='0'] {
                display: none;
            }

            > .card-body {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding: 0.5rem;
            }
        }
    }

    &.content-tree-panel {
        min-width: 200px;
        .drop-zone {
            display: none;
            height: 100%;
            overflow: hidden;
            padding: 0 0 0 1.5rem;
            position: absolute;
            top: 0;
            width: 100%;
        }
        .drop-zone,
        .list-group {
            align-items: flex-start;
            .drag-item {
                align-items: center;
                background-color: $primary !important;
                border: none !important;
                border-radius: 2rem;
                color: #ffffff !important;
                flex-direction: row;
                font-size: 0.9rem;
                height: 34px;
                justify-content: flex-start;
                margin: 0;
                padding: 0 0.75rem !important;
                width: auto;

                > i {
                    margin-right: 0.5rem;
                }
                > span {
                    flex-grow: 1;
                    line-height: 38px;
                    text-align: left;
                    width: auto;
                }
            }
            .list-group-item {
                align-items: flex-start !important;
                background-color: lighten($bg, 77%) !important;
                border: none;
                flex-direction: column;
                padding: 0 !important;

                &.list-group-screen-item {
                    cursor: default;
                    min-height: 38px;
                    .tree-item-title {
                        background-color: transparent !important;
                        padding: 0 0 0 2.25rem !important;
                    }
                }

                &[data-active='true'] {
                    margin-top: 0;

                    > .tree-item-children {
                        border: 2px dashed $primary !important;
                    }
                }

                > .tree-item-title {
                    align-items: center;
                    border-radius: 1rem;
                    display: flex;
                    padding: 0 0.75rem !important;

                    &:hover {
                        background-color: rgba(lighten($bg, 60%), 0.1);
                    }

                    .tree-expand-button {
                        box-shadow: none !important;
                        margin-left: -1.75rem;
                        padding: 0;
                        position: absolute;
                        transition: transform 0.3s ease;
                        will-change: transform;
                    }
                }
                > .tree-item-children {
                    display: none;
                    min-width: 100%;
                    padding: 0 0 0 1.5rem !important;

                    &:empty {
                        border: 1px dotted $primary;
                        cursor: default;
                        min-height: 38px;
                    }
                }
                &[aria-expanded='true'] {
                    > .tree-item-title {
                        > .tree-expand-button {
                            transform: rotate(90deg);
                        }
                    }
                    > .tree-item-children {
                        display: flex;
                    }
                }
            }
        }
    }

    &.elements-panel {
        min-width: 100px;
    }

    &.errors-panel {
        min-width: 180px;
    }

    &.properties-panel {
        min-width: 240px;

        .card-body {
            > a {
                display: block;
                > .btn {
                    text-transform: uppercase;
                    width: 100%;
                }
            }
            .card-title {
                & ~ .btn,
                & ~ div > .btn {
                    text-transform: uppercase;
                    width: 100%;
                }
                &:last-child {
                    margin: 0;
                }
            }
        }

        .ant-tabs,
        .element-properties {
            min-height: 100%;
        }

        .ant-tabs {
            .card-body {
                overflow: visible;
            }
        }

        .screen-wrapper {
            min-height: 100%;

            &[data-type='9'] {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                overflow: hidden;

                .card-body {
                    flex-grow: 0;
                }

                .ant-tabs {
                    .ant-tabs-content-holder {
                        overflow-y: auto;
                    }
                }
            }
        }

        .sort-handle {
            cursor: ns-resize;
        }

        .shading-picker-wrapper {
            margin-top: 0.3rem;
            .color-picker {
                display: flex;
                position: relative;

                > .color-picker-swatch {
                    height: 100%;
                    margin: 0;
                    overflow: hidden;
                    width: 100%;
                }
                .color-picker-swatch {
                    min-height: 2rem;
                    min-width: 2rem;
                    position: relative;
                }
            }
            .gradient-type-picker {
                height: 2rem;
                margin-bottom: 0.3rem;
                width: 100%;
                button {
                    > div {
                        border: 1px solid $gray-300;
                        border-radius: 0.3rem;
                        height: 100%;
                        width: 100%;
                    }
                }
            }
            .gradient-color-picker {
                width: 100%;
                button {
                    padding: 0;
                    overflow: visible;
                    z-index: unset;

                    .color-picker {
                        width: 100%;

                        .color-picker-swatch {
                            border: none;
                        }

                        .color-picker-swatch,
                        .color-picker-swatch:after {
                            border-radius: 0;
                        }
                    }
                }
            }
        }
    }

    &.screens-panel {
        min-width: 160px;
    }
}

.drag-item {
    align-items: center;
    background-color: transparent;
    border: 2px solid transparent !important;
    cursor: grab !important;
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    height: 70px;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    margin: 0.25rem;
    transition: background 0.3s ease, border 0.3s ease, color 0.6s ease;
    white-space: nowrap;
    width: 70px;
    > i {
        font-size: 1.5rem;
    }
    > span {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
    &.disabled {
        cursor: default !important;
        opacity: 0.2;
    }

    &:not(.disabled) {
        &:focus {
            background-color: lighten($bg, 75%);
            box-shadow: none !important;
        }
        &:hover {
            background-color: lighten($bg, 70%) !important;
        }
        &:active {
            background-color: lighten($bg, 75%) !important;
            border: 2px solid $primary !important;
        }
    }

    &.sortable-fallback {
        background-color: $primary !important;
        color: #ffffff !important;
        opacity: 0.2 !important;
    }
}

.sortable-fallback {
    &.list-group-item {
        &.property-item {
            display: none !important;
        }
        &:not(.property-item) {
            background-color: transparent;
            border: none;
            height: auto !important;
            padding: 0 !important;
            width: auto !important;

            > .tree-item-title {
                background-color: $primary !important;
                border-radius: 1rem;
                color: #ffffff !important;
                opacity: 0.2 !important;
                padding: 0.5rem 1rem !important;
            }

            .tree-expand-button,
            > .tree-item-children {
                display: none;
            }

            &.delete {
                align-items: center;
                display: flex;
                justify-content: center;

                &:before {
                    color: $danger;
                    font-size: 3rem;
                    position: absolute;
                    z-index: 1;
                }
            }
        }
    }
}

body.dragging-element {
    &[data-type='DRAG_ITEM_ELEMENT'] {
        &:not([data-element-type='6']) {
            .list-group-item {
                &.sortable-ghost {
                    > .tree-item-title {
                        color: $primary;
                    }
                }
                &:not(.sortable-ghost) {
                    &:hover {
                        > .tree-item-children {
                            display: flex !important;
                        }
                    }
                }
            }
        }
        &[data-element-type='6'] {
            .panel {
                [data-not-accept*='6'] {
                    border: none !important;
                    min-height: 0 !important;
                }
            }
        }
        &[data-body-type='vertical'] {
            .panel {
                [data-accept='vertical'] {
                    border: 2px solid $primary;
                    min-height: 38px;
                }
            }
        }
        &:not([data-body-type='vertical']) {
            .panel {
                [data-bars-length='0'] {
                    display: block;
                }
                [data-accept='DRAG_ITEM_ELEMENT'] {
                    &:not([data-body-type='vertical']) {
                        border: 2px solid $primary;
                        min-height: 38px;
                    }
                }
            }
        }
        [data-type='DRAG_ITEM_ELEMENT'] {
            z-index: 2;
        }
    }

    &[data-type='DRAG_ITEM_ELEMENT'] {
        &:not([data-element-type='6']) {
            &:not([data-body-type='vertical']) {
                .panel {
                    .drop-zone {
                        border: 2px solid $primary;
                        display: flex !important;
                        &:not(:empty) {
                            ~ .tree-item-title {
                                display: none;
                            }
                        }
                    }
                    [data-main-element-id] {
                        ~ .drop-zone {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }
    &[data-type='DRAG_ITEM_SCREEN'] {
        .panel {
            .drop-zone {
                display: flex !important;
                z-index: 2;
                &:not(:empty) {
                    ~ .tree-item-title {
                        display: none;
                    }
                }
            }
            [data-accept*='DRAG_ITEM_SCREEN'] {
                border: 2px solid $primary;
                min-height: 38px;
            }
        }
    }
}
