.StripeElement {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    height: 38px;
    color: $input-color;
    background-color: $input-bg;
    border-style: solid;
    border-color: $input-border-color;
    border-width: $input-border-width;
    background-clip: padding-box;
    border-radius: 0.3rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.StripeElement--focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    @if $enable-shadows {
        box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
        box-shadow: $input-focus-box-shadow;
    }
}

.checkout {
    #Card0 {
        & ~ div {
            display: none;
        }

        &:checked ~ div {
            display: block;
        }
    }
}

@media screen and (prefers-reduced-motion: reduce) {
    .StripeElement {
        transition: none;
    }
}
