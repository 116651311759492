// -----------------------------------------------------------------------------
// This file contains styles that are specific to not found page.
// -----------------------------------------------------------------------------

.maintenance {
    .jumbotron {
        background-size: 100%;
        background-color: #f0f1f3;
        background-repeat: no-repeat;
        background-position: center bottom;
        height: 100vh;
    }
}
