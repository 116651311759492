.json-editor {
    border-left: 1px solid $gray-300;
    height: 100%;
    width: 100%;

    + .save {
        top: 0px;
        left: 100px;
        height: 26px;
        position: absolute;
        width: 26px;
    }

    .jsoneditor {
        border: none;

        > .jsoneditor-menu {
            display: none;
        }

        .jsoneditor-outer {
            margin: 0;
            padding: 0;
            .ace-github {
                color: $primaryText;
                font-size: 1rem;
                .ace_gutter {
                    background-color: $light;
                }
                .ace_string {
                    color: #008000;
                }
                .ace_numeric {
                    color: #0000ff;
                }
            }
        }

        .jsoneditor-additional-errors {
            display: none !important;
        }

        tr {
            display: flex;
            td {
                display: block;
                max-width: unset;
                width: auto;
                &:last-child {
                    flex-grow: 1;
                }
            }
        }
    }
}
