.sidebar-wrapper {
    padding: 0;

    .btn {
        box-shadow: none !important;
    }

    .sidebar-toggler {
        align-items: center;
        position: fixed;
        border: none;
        display: flex;
        font-size: 1.5rem;
        margin: 0 0 0 1rem;
        padding: 0 0 0 0.5rem !important;
        top: 7px;
        z-index: 11;
        &.position-fixed {
            top: 1000%;
        }
        &:after {
            content: '';
            position: fixed;
            top: 1000%;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.35);
            opacity: 0;
            transition: top 0s ease 0.35s, opacity 0.35s ease;
        }

        img {
            margin-left: 1rem;
            height: 70%;
        }
    }

    .navbar-collapse {
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        transition: transform 0.3s ease;
        transform: translate(-100vw);
        z-index: 12;

        &.show {
            transform: translate(0);
            & ~ .sidebar-toggler {
                cursor: default;
                &:after {
                    top: 0;
                    opacity: 1;
                    transition: top 0s ease 0s, opacity 0.35s ease;
                }
            }
        }
    }

    .sidebar {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        &.sidebar-main {
            background-color: lighten($primary, 35%);
            width: 64px;

            .nav-item {
                margin: 0.25rem 0;
            }
        }
        &.sidebar-menu {
            width: 240px;

            .nav-item {
                &.tree-wrapper {
                    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
                }
            }
        }
        &.sidebar-creation,
        &.sidebar-search {
            width: 100vw;
            max-width: 600px;

            .navbar-brand {
                align-self: flex-start;
                width: 64px;
                text-align: center;
            }
        }
        .nav {
            height: 100vh;

            .navbar-brand {
                margin: 0;
            }

            .project-shortcut {
                border: 2px solid $gray-700;
                border-radius: 0.25rem !important;
                overflow: hidden;
                max-height: 30px;
                max-width: 30px;
                min-height: 30px;
                min-width: 30px;
                margin: 5px 0;

                > a {
                    display: block;
                }
                img {
                    border: 2px solid transparent;
                    border-radius: 0.25rem;
                }
            }

            .dropdown {
                &.apps {
                    .dropdown-toggle {
                        padding: 0 0.5rem;
                        position: relative;
                        &:after {
                            position: absolute;
                            bottom: 0;
                        }
                        img {
                            box-shadow: none !important;
                        }
                    }
                    .dropdown-menu {
                        padding: 0.5rem;
                        font-size: 0.8rem;
                        > div {
                            display: flex;
                        }
                        .dropdown-header {
                            width: 100%;
                        }
                        .dropdown-item {
                            align-items: center;
                            border-radius: 0.25rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            padding: 0.25rem;
                            width: 100px;
                            &:active {
                                background-color: $gray-100;
                                color: $gray-900;
                            }

                            img {
                                margin: 0.25rem auto;
                            }
                        }
                    }
                }

                &.notifications {
                    [data-any-new='false'] {
                        > i {
                            color: darken($secondary, 10%);
                        }

                        &:active,
                        &:focus,
                        &:hover {
                            > i {
                                color: #ffffff;
                            }
                        }
                    }
                    .dropdown-menu {
                        bottom: 10px;
                        flex-direction: column;
                        top: unset !important;
                        transform: translateX(40px) !important;
                        white-space: nowrap;
                        width: 320px;

                        &.show {
                            display: flex;
                        }
                    }
                    .badge {
                        bottom: 20px;
                        position: absolute;
                        right: -5px;
                        top: unset;
                        z-index: 1;
                    }
                }
                .dropdown-menu {
                    font-size: 0.9rem;
                    max-height: 50vh;
                    overflow: auto;
                }
                .nav-link {
                    &[aria-expanded='true'] {
                        img {
                            box-shadow: 0 0 0 2px #fff, 0 0 0 0.3rem $primary;
                        }
                    }
                }
            }
        }
    }
}

.project {
    background-color: #fff;
    display: flex;
    position: relative;

    .project-body {
        align-items: center;
        font-size: 0.9rem;
        line-height: 1rem;
        position: relative;
        z-index: 1;
    }

    .project-bg {
        position: absolute;
        top: -1rem;
        right: -1rem;
        bottom: -1rem;
        left: -1rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        opacity: 0.3;
        transition: background 0.5s;
        filter: blur(16px);
    }
}

.projects-modal {
    .project {
        height: 216px;
        width: 160px;
        transition: background 0.3s ease;

        .dropdown {
            display: inline;
            position: absolute;
            z-index: 2;
            right: 0;
            top: 0;
            .dropdown-menu {
                .dropdown-item {
                    border-radius: 0 !important;
                    &:active {
                        color: #fff;
                        text-decoration: none;
                        background-color: $primary;
                    }
                }
            }
        }

        .project-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            padding: 1rem;

            a {
                display: block;
                z-index: 1;
                img {
                    height: 128px;
                }
            }
        }
        .card-footer {
            background-color: #ffffff;
            border: none;
            color: $gray-700;
            font-size: 0.9rem;
            overflow: hidden;
            padding: 1rem;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &:hover {
            background-color: $gray-300;
        }
    }
}

@media (min-width: 768px) {
    .sidebar-wrapper {
        [toggler='#SidebarToggler'] {
            transform: translate(0);
        }
        & ~ .container-wrapper {
            margin-left: 64px;
        }
        &.sidebar-wide {
            & ~ .container-wrapper {
                margin-left: 304px;
            }
        }
    }
}
