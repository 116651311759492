.tools {
    display: flex;
    height: 80%;
    width: 60%;
    .modal-content {
        height: 100%;
        img {
            height: 64px;
            margin-bottom: 2rem;
        }
    }

    .card-deck {
        margin: 1rem -0.5rem !important;
        .card {
            .card-header {
                background-color: transparent;
                border: none;
                .card-title {
                    margin: 0;
                }
            }
            .card-body {
                padding: 0 0 1rem;
                .icon-success {
                    color: $success;
                    display: inline-block;
                    font-size: 5.5rem;
                    margin: -1rem 0 0rem;
                }
            }
            .card-footer {
                text-align: right;
            }
        }
    }

    input {
        width: 300px;
    }
}
