// -----------------------------------------------------------------------------
// This file contains all styles related to the progress component.
// -----------------------------------------------------------------------------

.progress {
    position: relative;
    height: 4px;
    min-height: 4px;
    display: block;
    width: 100%;
    background-color: lighten($primary, 35%);
    border-radius: 2px;
    background-clip: padding-box;
    overflow: hidden;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 0.2s ease;
    will-change: height, transform;

    .determinate {
        position: absolute;
        background-color: inherit;
        top: 0;
        bottom: 0;
        background-color: $primary;
        transition: width 0.3s linear;
    }

    .indeterminate {
        background-color: $primary;
        height: 100%;
        visibility: hidden;

        &:before {
            content: '';
            position: absolute;
            background-color: inherit;
            top: 0;
            left: 0;
            bottom: 0;
            will-change: left, right;
            animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
            visibility: visible;
        }
        &:after {
            content: '';
            position: absolute;
            background-color: inherit;
            top: 0;
            left: 0;
            bottom: 0;
            will-change: left, right;
            animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation-delay: 1.15s;
            visibility: visible;
        }
    }

    &.show,
    .indeterminate:before,
    .indeterminate:after {
        transform: scale(1);
    }
}

@keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%;
    }
    60% {
        left: 100%;
        right: -90%;
    }
    100% {
        left: 100%;
        right: -90%;
    }
}
@keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%;
    }
    60% {
        left: 107%;
        right: -8%;
    }
    100% {
        left: 107%;
        right: -8%;
    }
}
