// -----------------------------------------------------------------------------
// This file contains all styles related to the search component.
// -----------------------------------------------------------------------------

.search {
    align-items: center;
    justify-content: flex-end;
    display: flex;

    .input-wrapper {
        overflow: hidden;
        transition: width 0.3s ease;
        width: 0;
        will-change: width;
        &[data-visible='true'] {
            width: 200px;
        }
    }

    button {
        min-width: 32px;
    }
}

.card-header {
    .search {
        .input-wrapper {
            &[data-visible='true'] {
                margin-left: 0.5rem;
                width: 100%;
            }
            input {
                background-color: transparent;
                border: none;
                border-radius: 0;
                border-bottom: 2px solid;
                color: lighten($bg, 30%);
                font-size: 0.9rem;
                height: 1.5rem;
                padding: 0;
            }
        }
    }
}
