// -----------------------------------------------------------------------------
// This file contains all styles related to the resouce component.
// -----------------------------------------------------------------------------

.bars {
    max-width: 444px;
    width: 444px;
    .card {
        cursor: pointer;
        max-width: 396px;
        min-width: 396px;
        position: relative;

        &.bar--checked {
            border: 1px solid $primary;
            box-shadow: 0 0 0 1px $primary !important;
        }

        &:before {
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 3;
        }

        &[disabled] {
            background-color: $gray-300;
            opacity: 0.2;
        }

        .card-body {
            font-size: 0.9rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .card-title {
                margin: 0;
            }

            > .element-wrapper {
                box-shadow: 0 0 0 1px $gray-300;
            }

            + .card-body {
                padding-top: 0;
            }
        }
    }
}
