// -----------------------------------------------------------------------------
// This file contains all styles related to the resouce component.
// -----------------------------------------------------------------------------

.resource {
    cursor: pointer;
    max-width: 140px;
    min-width: 140px;

    &:hover {
        .icon {
            .bg {
                &:before {
                    background-color: rgba(255, 255, 255, 0.6);
                }
            }
        }
    }

    &[data-type='image'] {
        .card-img-top {
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAJ0lEQVQoU2O8e/fufwYkoKSkhMxlYKSDgv///6O44d69e6huoL0CALpMKlF6PO5uAAAAAElFTkSuQmCC);
        }
    }

    &.resource--checked,
    &.resource--current {
        border: 1px solid $primary;
        box-shadow: 0 0 0 1px $primary !important;
    }

    .icon {
        align-items: center;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;
        width: 100%;
        &:before {
            content: '';
            display: block;
            padding-top: 100%;
            width: 100%;
        }

        i {
            position: absolute;
            font-size: 6rem;
        }
        .img {
            background: no-repeat center / cover;
            height: 100%;
            position: absolute;
            width: 100%;
        }
        audio,
        img,
        video {
            outline: none;
            position: absolute;
            width: 80%;
            z-index: 1;
        }
    }

    .card-body {
        input {
            font-size: 0.9rem;
            height: 1.75rem;
            margin: -0.5rem -0.5rem 0;
            overflow: hidden;
            padding: 0 0.5rem;
            text-overflow: ellipsis;
            width: calc(100% + 1rem);
            white-space: nowrap;
            &:not(:focus) {
                &:not(:hover) {
                    border-color: transparent;
                }
            }
        }
    }
}

.unsplash {
    .resource {
        .card-img-top {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        .card-body {
            .card-title {
                text-overflow: unset;
                white-space: normal;
            }
        }
    }
}

.resources {
    display: flex;
    height: 80%;
    width: 80%;
    .modal-content {
        height: 100%;
    }
}

.url-modal {
    width: 400px;
}
