// -----------------------------------------------------------------------------
// This file contains styles that are specific to plans page.
// -----------------------------------------------------------------------------

.plans {
    .card {
        min-width: 220px;
        max-width: 500px;

        .card-header {
            display: flex;
            justify-content: center;
            position: relative;

            .badge {
                bottom: -10px;
                position: absolute;
            }
        }
    }
}
