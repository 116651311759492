// -----------------------------------------------------------------------------
// This file contains all styles related to the visor of the site/application.
// -----------------------------------------------------------------------------

.visor {
    margin: 1rem;
    position: relative;
    &:before {
        content: '';
        display: block;
        padding-top: 178%;
        width: 354px;
    }

    .ql-bubble {
        .ql-tooltip {
            position: fixed;
        }
        .ql-tooltip-arrow {
            margin-left: -6px !important;
        }
    }

    &[data-landscape='true'] {
        &:before {
            padding-top: 56.2%;
            width: 630px;
        }
    }

    .bg {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
    }

    > .screen-wrapper {
        background-color: $gray-100;
        border-radius: 0.5rem;
        box-shadow: 0 0.5rem 1rem rgba(lighten($bg, 50%), 0.4);
        bottom: 0;
        box-sizing: inherit;
        display: flex;
        flex-direction: column;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;

        .drag-item:not(.disabled) {
            background-color: $primary !important;
            color: #ffffff !important;
            flex-direction: row;
            height: auto;
            justify-content: center;
            margin: 3px auto;
            max-width: unset !important;
            min-width: unset !important;
            width: calc(100% - 0.5rem) !important;
            > i {
                margin-right: 0.5rem;
            }
            > span {
                width: auto;
            }
        }

        .screen-image {
            background: center top no-repeat;
            border-radius: 0.5rem;
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 0;

            &[data-halign='left'] {
                background-position-x: left;
            }
            &[data-halign='center'] {
                background-position-x: center;
            }
            &[data-halign='right'] {
                background-position-x: right;
            }

            &[data-valign='top'] {
                background-position-y: top;
            }
            &[data-valign='middle'] {
                background-position-y: center;
            }
            &[data-valign='bottom'] {
                background-position-y: bottom;
            }
        }

        > .bar-wrapper {
            overflow: hidden;
            &[data-position='top'],
            &[data-position='top']:empty ~ .screen-main-content {
                border-top-left-radius: 0.5rem;
                border-top-right-radius: 0.5rem;
            }
            &[data-position='top'] {
                order: 1;
            }
            &[data-position='bottom'],
            &[data-position='bottom']:empty ~ .screen-main-content {
                border-bottom-left-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;
            }
            &[data-position='bottom'] {
                order: 3;
            }
        }

        > .screen-main-content {
            flex-grow: 1;
            order: 2;
            overflow: auto;
            position: relative;
            z-index: 1;

            > .drop-zone {
                display: none;
                height: 100% !important;
                position: absolute;
                width: 100% !important;
                z-index: 2;

                > .drag-item {
                    background-color: rgba($primary, 0.9) !important;
                    bottom: 0;
                    color: #ffffff !important;
                    flex-direction: column;
                    font-size: 1.2rem;
                    height: calc(100% - 0.5rem);
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: calc(100% - 0.5rem);

                    > i {
                        font-size: 6rem;
                        line-height: 6rem;
                    }
                }
            }

            > .element-wrapper {
                display: flex;
                min-height: 100% !important;
                opacity: 1 !important;
                border: none !important;
                position: absolute;
                top: 0;
                width: 100% !important;
                z-index: 1 !important;
                > .element-handler {
                    display: none;
                }
                > .element-content {
                    height: auto;
                    min-height: 100%;
                    opacity: 1 !important;
                    width: 100%;
                }
            }

            > .skeleton {
                align-items: center;
                background-color: rgba(#ffffff, 0.8);
                display: flex;
                flex-direction: column;
                font-size: 1.2rem;
                justify-content: center;
                min-height: 100%;
                z-index: 1;

                > i {
                    font-size: 6rem;
                    line-height: 6rem;
                }
            }
        }

        &[data-screen-type='0'] {
            > .screen-main-content {
                > .skeleton {
                    > i {
                        color: lighten($bg, 70%);
                    }
                }
            }
        }
        &[data-screen-type='9'] {
            .iframe-blocker {
                display: none;
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 1;
            }
            iframe {
                border: none;
                height: 100%;
                position: absolute;
                width: 100%;
                z-index: 0;
            }
        }

        .drag-item,
        .sortable-ghost {
            height: auto;
            max-width: unset !important;
            min-width: unset !important;
        }
        .drag-item {
            color: #ffffff !important;
            flex-direction: row;
            justify-content: center;
            margin: 0.25rem !important;
            width: calc(100% - 0.5rem) !important;
            > span {
                width: auto;
            }
        }
    }
}

.element-wrapper,
.bar-wrapper {
    .drag-item {
        > i {
            margin-right: 0.5rem;
        }
    }
}

.element-wrapper {
    position: relative;
    width: 100%;
    will-change: width, margin;
    z-index: 1;

    &:hover,
    &.hover,
    &.element--current {
        > .element-handler {
            border: 2px solid $primary;
        }
    }
    &.element--current {
        opacity: 1 !important;
        z-index: 2;
    }
    &:hover,
    &.hover {
        opacity: 0.9;
        z-index: 3;
    }

    &.sortable-fallback {
        cursor: grabbing !important;
        max-width: unset !important;
        min-width: unset !important;
        opacity: 1 !important;
        visibility: visible;
        height: auto !important;

        > .element-content {
            border: 2px solid $primary !important;
            opacity: 0.2 !important;
        }

        &.delete {
            &:before {
                align-items: center;
                color: $danger;
                display: flex;
                font-size: 3rem;
                height: 100%;
                justify-content: center;
                position: absolute;
                width: 100%;
                z-index: 1;
            }
            .element-handler {
                border: 2px solid $danger;
                display: block;
            }
        }
    }

    .element-handler {
        bottom: 0;
        cursor: grab;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }

    .element-content {
        height: 100%;
        position: relative;
        z-index: 0;
    }

    &[data-halign='left'] {
        margin: 0;
    }
    &[data-halign='center'] {
        margin: 0 auto;
    }
    &[data-halign='right'] {
        margin: 0 0 0 auto;
    }

    &[data-valign='top'] {
        > .element-content {
            justify-content: flex-start;
        }
    }
    &[data-valign='middle'] {
        > .element-content {
            justify-content: center;
        }
    }
    &[data-valign='bottom'] {
        > .element-content {
            justify-content: flex-end;
        }
    }

    &[data-element-type='1'] {
        img {
            display: block;
            width: 100%;
        }
    }

    &[data-element-type='2'] {
        color: #000;
        [contenteditable] {
            cursor: text;
            outline: none;
            &:not(:focus) {
                &:empty {
                    &:before {
                        color: $gray-500;
                        content: attr(placeholder);
                        height: 100%;
                        left: 0;
                        overflow: auto;
                        position: absolute;
                        text-overflow: ellipsis;
                        top: 0;
                        white-space: nowrap;
                        width: 100%;
                        z-index: -1;
                    }
                }
            }
        }
        > .element-content {
            border: 2px solid transparent;
            min-height: 27px;
        }

        &.element--current {
            > .element-handler {
                display: none;
            }
            > .element-content {
                border-color: $primary;
            }
        }

        &[data-font-size='default'] {
            font-size: 12px;
        }
        &[data-font-size='tiny'] {
            font-size: 6pt;
        }
        &[data-font-size='small'] {
            font-size: 12pt;
        }
        &[data-font-size='medium'] {
            font-size: 15pt;
        }
        &[data-font-size='large'] {
            font-size: 18pt;
        }
        &[data-font-size='huge'] {
            font-size: 23pt;
        }
    }

    &[data-element-type='3'] {
        padding: 0.3rem 0;
        hr {
            border-top: 2px solid rgba(0, 0, 0, 0.1);
            margin: 0;
            &[data-line-style='none'] {
                border-style: none;
            }
            &[data-line-style='solid'] {
                border-style: solid;
            }
            &[data-line-style='dashed'] {
                border-style: dashed;
            }
            &[data-line-style='dotted'] {
                border-style: dotted;
            }
            &[data-line-style='shaded'] {
                border-style: ridge;
            }
        }
    }

    &[data-element-type='4'] {
        input {
            border: 1px solid #000000;
            border-radius: 3px;
            padding: 0.25rem;
            width: 100%;
        }
    }

    &[data-element-type='5'],
    &[data-element-type='6'] {
        &.descendant--current,
        &.element--current {
            opacity: 1 !important;
            z-index: 2;
            > .element-handler {
                display: none !important;
            }
            > .element-content {
                overflow-x: auto;
            }
        }
        &.element--current {
            > .element-content {
                border: 2px dashed $primary !important;
            }
        }
        > .element-content {
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
        &[data-loaded='true'] {
            > .element-content {
                &:empty {
                    border: 1px dotted $primary;
                    min-height: 46px !important;
                }
            }
        }
        &[data-body-type='horizontal'] {
            > .element-content {
                flex-direction: row;

                > .drag-item,
                > .sortable-ghost {
                    height: unset;
                    margin: 0;
                    min-width: 3px;
                    width: 3px;
                }
            }
            &.descendant--current,
            &.element--current {
                > .element-content {
                    > .element-wrapper[data-body-type='vertical'] {
                        > .element-content {
                            border: 1px dotted $primary;
                        }
                    }
                }
            }
        }

        &[data-body-type='vertical'] {
            display: flex;
            flex-direction: column;
            &.descendant--current {
                > .element-content {
                    border: 1px dotted $primary;
                }
            }
            > .element-content {
                flex-direction: column;
            }
        }
    }

    &[data-element-type='11'] {
        video {
            display: block;
            width: 100%;
        }
    }
}

body.dragging-element {
    .sortable-fallback {
        [data-body-type='vertical'] {
            > .element-content {
                border: none !important;
            }
        }
    }
    &[data-type='DRAG_ITEM_ELEMENT'],
    &[data-type='DRAG_ITEM_SCREEN'] {
        cursor: grabbing !important;
        .drag-item {
            cursor: grabbing !important;
        }
    }
    &[data-type='DRAG_ITEM_SCREEN'] {
        .screen-main-content {
            border: 2px solid $primary;
        }
        .drop-zone {
            display: flex !important;
        }
        [data-not-accept*='DRAG_ITEM_SCREEN'] {
            display: none !important;
        }
    }
    &[data-type='DRAG_ITEM_ELEMENT'] {
        &:not([data-element-type='6']) {
            &:not([data-body-type='vertical']) {
                .screen-wrapper:not([data-screen-type='3']) {
                    .drop-zone {
                        border: 2px solid $primary;
                        display: flex !important;
                    }
                }
            }
        }
        .element-wrapper {
            border: none !important;

            .element-handler {
                display: none;
            }
        }
        &[data-body-type='vertical'] {
            .element-content {
                &[data-accept='vertical'] {
                    border: 2px solid $primary;
                }
            }
        }
        &:not([data-body-type='vertical']) {
            .bar-wrapper {
                border: 2px solid $primary;
                z-index: 1;
                &:empty {
                    min-height: 52px;
                }
            }
            .screen-main-content {
                border-radius: 0 !important;
            }
            &:not([data-element-type='6']) {
                .screen-main-content {
                    margin: -2px 0;
                }
            }

            [data-element-type='6'],
            [data-body-type='vertical'] {
                > .element-content {
                    &[data-accept='DRAG_ITEM_ELEMENT'] {
                        border: 2px solid $primary;
                    }
                }
            }
        }
        &[data-element-type='6'] {
            .element-content {
                &[data-not-accept*='6'] {
                    border: none !important;
                    min-height: 0;
                }
            }
        }
    }
}
