// -----------------------------------------------------------------------------
// This file contains all styles related to the resouce component.
// -----------------------------------------------------------------------------

.welcome {
    height: 500px;
    max-width: 700px;
    min-height: 500px;
    width: 700px;
    .modal-content {
        height: 100%;
    }

    .carousel {
        flex-grow: 1;
        max-width: calc(70% - 1rem);
        min-height: 100%;
        min-width: calc(70% - 1rem);
        width: calc(70% - 1rem);
        margin-right: 1rem;

        .carousel-indicators {
            bottom: 1rem;
            position: absolute;
        }

        .carousel-inner {
            align-items: center;
            border-radius: 0.3rem;
            display: flex;
            height: 100%;
            overflow: hidden;

            .carousel-item {
                text-align: center;
                height: 100%;
                overflow: hidden;
                padding-top: 1rem;
                > div {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                img {
                    height: 270px;
                    width: auto;
                }
                h3 {
                    color: $primary;
                }
                .carousel-caption {
                    background: transparent;
                    padding: 1rem 2rem;
                    position: relative;
                    z-index: 1;
                }
            }
        }
        .application {
            align-items: center;
            background: transparent;
            border: none;
            box-shadow: none !important;
            max-width: 100%;
            padding: 0.5rem;
            width: 80%;
            &:before {
                bottom: -160px;
                content: '';
                left: -50px;
                position: absolute;
                right: -50px;
                top: -20px;
                z-index: 2;
            }
            .card-img-top {
                overflow: visible;
                width: 80%;
                img {
                    height: 250px;
                }
                .bg {
                    background-repeat: no-repeat;
                    background-size: contain;
                    filter: blur(3rem);
                    transform: scale(2) translate(0, 2.3rem);
                }
            }
            .card-body {
                display: none;
            }
        }
    }

    .btn-group-vertical {
        flex-grow: 0;
        max-width: 30%;
        min-width: 30%;
        width: 30%;

        .btn {
            border: none;
            font-weight: bold;
            min-height: 2rem;

            small,
            span {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
            }
            &.bg-image {
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
                height: 100px;
                min-height: 100px;
                overflow: hidden;
                padding: 0 2rem;
                text-transform: uppercase;
                transition: all 0.3s ease;
                white-space: normal;
                will-change: background;
                &:hover {
                    background-size: 120%;
                }

                .bg {
                    bottom: 0;
                    background-color: inherit;
                    left: 0;
                    opacity: 0.8;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 0;
                }
                span {
                    font-size: 1rem;
                    position: relative;
                    z-index: 1;
                }
            }
            a {
                color: inherit;
                display: block;
                padding: 0.375rem 0.75rem;
            }
        }
    }
}

.ant-popover.recent-applications {
    z-index: 1051;
}
