// -----------------------------------------------------------------------------
// This file contains all styles related to the alerts of the site/application.
// -----------------------------------------------------------------------------

.alert-wrapper {
    align-items: initial;
    bottom: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    right: 0;
    z-index: 1051;

    h6,
    .alert {
        padding: 0 1rem;
    }

    h6 {
        background-color: #eeeeee;
        color: #999999;
        font-size: 0.8rem;
        line-height: 2rem;
        margin: 0;
        &.new {
            background-color: $primary;
            color: #ffffff;
            order: 0;
        }
        &.old {
            order: 2;
        }
    }

    > .alert {
        align-items: flex-start;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 0;
        color: inherit;
        display: flex;
        font-size: 0.9rem;
        line-height: 3rem;
        margin: 0;
        order: 3;
        white-space: nowrap;

        &:hover {
            background-color: $light;
        }

        &[data-new='true'] {
            background-color: rgba($primary, 0.3);
            order: 1;

            &:hover {
                background-color: rgba($primary, 0.4);
            }
        }

        &:first-of-type {
            border-top: 1px solid rgba(0, 0, 0, 0.05);
        }

        &.alert-danger i {
            color: $danger;
        }
        &.alert-info i {
            color: $info;
        }
        &.alert-success i {
            color: $success;
        }
        &.alert-warning i {
            color: $warning;
        }

        > i {
            font-size: 1.2rem;
            margin: 0 0.5rem 0 0;
        }

        > .message {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            font-size: 0.8rem;
            line-height: 1rem;
            overflow: hidden;
            padding: 0.5rem 0;
            white-space: normal;
            width: 0;

            > div {
                align-items: flex-start;
                display: flex;

                span {
                    flex-grow: 1;
                }
            }

            .expand-button {
                font-size: 1.5rem;
                line-height: 2rem;
                margin-bottom: -2rem;
                padding: 0;
                > i {
                    color: rgba(0, 0, 0, 0.4);
                }
                &:hover {
                    > i {
                        color: rgba(0, 0, 0, 0.8) !important;
                    }
                }
            }

            > .from-now {
                color: rgba($gray-600, 0.6);
                margin-top: 0.15rem;
            }

            .description {
                font-size: 0.8em;
                height: 0;
                line-height: 1rem;
                margin: 0;
                overflow: hidden;
                padding: 0;

                > canvas {
                    box-shadow: 0 0 0 4px #ffffff;
                    margin: 1rem auto;
                }

                > ul {
                    margin-bottom: 0.5rem;
                    padding-left: 1.5rem;
                }
            }

            &.expanded {
                .description {
                    height: auto;
                }
                .expand-button {
                    > i {
                        color: rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }

        > .close {
            line-height: 3rem;
            order: 2;
            padding: 0 0 0 0.5rem;
            position: relative;
        }

        .display-progress {
            align-items: center;
            display: flex;
            margin-left: 1rem;
            max-width: 40px;
            min-width: 40px;
            text-align: right;
            width: 40px;
        }

        > .progress {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            background-color: transparent;

            > .progress-bar {
                overflow: hidden;
                display: flex;

                > span {
                    align-items: center;
                    text-align: left;
                    margin: 0 0 0 1rem;
                    font-size: 0.9rem;
                    display: flex;

                    > span:first-child {
                        flex-grow: 1;
                    }

                    > .btn {
                        margin-right: 1.25rem !important;
                    }
                }
            }
        }
    }
}

[data-mobile='true'] {
    .alert-wrapper {
        top: 56px;
    }
}
