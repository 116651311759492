// -----------------------------------------------------------------------------
// This file contains all styles related to the checkbox component.
// -----------------------------------------------------------------------------

.checkbox {
    height: 1rem;
    margin: 0;
    min-height: 1rem;
    min-width: 1rem;
    overflow: hidden;
    position: relative;
    width: 1rem;
    i {
        background-color: #ffffff;
        border: 1px solid lighten($bg, 70%);
        border-radius: 0.25rem;
        color: transparent;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 0;
        &:before {
            font-size: 1rem;
            position: absolute;
        }
    }
    input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
        &:checked {
            + i {
                background-color: $primary;
                border-color: $primary;
                color: #ffffff;
                &:before {
                    font-weight: bold;
                }
            }
        }
    }
}
