// -----------------------------------------------------------------------------
// This file contains all styles related to the actions component.
// -----------------------------------------------------------------------------

.menu-actions {
    align-items: center;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;

    > button,
    > .dropdown > button,
    > .search > button {
        align-items: center;
        background-color: transparent !important;
        border: none;
        border-radius: 1rem;
        box-shadow: none !important;
        color: lighten($bg, 30%) !important;
        display: flex;
        height: 32px;
        justify-content: center;
        padding: 0;
        width: 32px;
        &[disabled] {
            opacity: 0.2;
        }
        &:not([disabled]) {
            &:focus {
                background-color: transparent !important;
            }
            &:hover {
                background-color: lighten($bg, 73%) !important;
            }
            &:active {
                background-color: lighten($bg, 75%) !important;
            }
        }
        span {
            display: none;
        }
    }
}

.card-header {
    .dropdown {
        button {
            &[disabled] {
                opacity: 0.2;
            }
            i {
                font-size: 1.2rem;
            }
            i,
            span {
                color: lighten($bg, 30%) !important;
            }
        }
        .dropdown-menu {
            border: none;
            i {
                display: none;
            }
        }
    }
}

.modal-header {
    .menu-actions {
        > button,
        > .dropdown > button,
        > .search > button {
            color: #000000 !important;
            font-size: 1.75rem;
            height: 40px;
            opacity: 0.5;
            transition: all 0.3s ease;
            width: 40px;
            will-change: width, transform;
            &[disabled] {
                transform: scale(0);
                width: 0;
            }
            &:not([disabled]) {
                &:focus,
                &:hover,
                &:active {
                    background-color: transparent !important;
                    opacity: 0.75;
                }
            }
        }
        .search {
            margin-right: 0.5rem;
        }
        .dropdown {
            .dropdown-menu {
                border: none;
                i {
                    display: none;
                }
            }
        }
    }
}
