// -----------------------------------------------------------------------------
// This file contains all styles related to the application component.
// -----------------------------------------------------------------------------

.application {
    cursor: pointer;
    max-width: 140px;
    min-width: 140px;

    &:hover {
        .icon {
            .bg {
                &:before {
                    background-color: rgba(255, 255, 255, 0.6);
                }
            }
        }
    }

    .icon {
        align-items: center;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;
        width: 100%;

        &:before {
            content: '';
            display: block;
            padding-top: 100%;
            width: 100%;
        }
        img {
            border-radius: 1.2rem;
            opacity: 0;
            position: absolute;
            transition: opacity 1s ease;
            width: 80%;
            will-change: opacity;
            z-index: 3;
            + img {
                opacity: 1;
                z-index: 2;
                + .bg {
                    opacity: 0;
                    z-index: 1;
                    + .bg {
                        opacity: 1;
                        transition-delay: 1s;
                        z-index: 0;
                    }
                }
            }

            &.loaded {
                opacity: 1;
                + img {
                    opacity: 0;
                    + .bg {
                        opacity: 1;
                        + .bg {
                            opacity: 0;
                        }
                    }
                }
            }
        }
        .bg {
            background-position: center;
            filter: blur(0.5rem);
            bottom: 0;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            opacity: 0;
            transform: scale(2);
            transition: opacity 1s ease;
            will-change: opacity;
            z-index: 1;

            &:before {
                background-color: rgba(255, 255, 255, 0.4);
                bottom: 0;
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
            }
        }
    }

    .card-body {
        font-size: 0.9rem;
    }
}

.applications {
    display: flex;
    max-width: 812px;
    min-height: 320px;
    width: 812px;

    .modal-content {
        height: 100%;
    }
}
