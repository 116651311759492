// -----------------------------------------------------------------------------
// This file contains styles that are specific to the sources page.
// -----------------------------------------------------------------------------

.sources {
    .d-sources-none {
        display: none !important;
    }

    .card {
        align-items: center;
        flex-grow: 0;
        margin-bottom: 1.5rem !important;

        &[data-default='true'] {
            background-color: transparent !important;
            border: 1px solid $success !important;
            cursor: default !important;
        }

        .badge {
            position: absolute;
            top: -0.5rem;
        }

        .card-body {
            align-items: center;
            display: flex;
            justify-content: center;
            width: 100%;
            > div {
                flex-grow: 1;
            }
        }

        .card-footer {
            background: none;
            border: none;
        }
    }

    .custom-control {
        padding: 0;
    }
}

.checkout {
    .sources {
        flex-direction: column;

        .card {
            border: none;
            box-shadow: none !important;
            cursor: pointer;
            margin-bottom: 0.5rem !important;
            transition: background 0.2s ease;

            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }

            .card-body {
                padding: 0.5rem;
                div,
                h6 {
                    align-items: center;
                    display: flex;
                    margin: 0 1rem 0 0;
                    padding: 0;
                }
            }
        }

        ~ .btn {
            width: 100%;
        }

        .card-footer {
            display: none;
        }
    }

    .intervals {
        .card {
            white-space: nowrap;
        }
    }
}
