// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts

@keyframes drawerIn {
    0% {
        visibility: hidden;
    }

    100% {
        visibility: visible;
    }
}

@keyframes drawerOut {
    0% {
        visibility: visible;
    }

    100% {
        visibility: hidden;
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes zoomIn {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(2);
    }
}

@keyframes zoomInAndFadeOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes heartBeat {
    0% {
        transform: scale(1);
    }

    14% {
        transform: scale(1.05);
    }

    28% {
        transform: scale(1);
    }

    42% {
        transform: scale(1.05);
    }

    70% {
        transform: scale(1);
    }
}

@keyframes heartbeatOpacity {
    0% {
        opacity: 1;
    }
    20% {
        opacity: 0.6;
    }
    40% {
        opacity: 1;
    }
    60% {
        opacity: 0.6;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes placeholderSkeleton {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

@keyframes skeletonAnimate {
    from {
        background-position: top left;
    }
    to {
        background-position: top right;
    }
}
