// -----------------------------------------------------------------------------
// This file contains all styles related to the preview component.
// -----------------------------------------------------------------------------

.preview-modal {
    .powered {
        bottom: 100px;
        margin: 0.5rem 1rem;
        position: fixed;
        z-index: 9;
        small {
            color: rgba($gray-600, 0.3);
            font-size: 10px;
        }
        img {
            display: block;
            filter: brightness(0) opacity(0.2);
            width: 80px;
        }
    }
    .modal-content {
        .modal-header {
            .modal-title {
                .menu-actions {
                    margin: 0 -0.8rem 0 0;
                    position: relative;

                    i {
                        color: $primary;
                    }
                }
            }
        }
    }
}
.preview {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    width: 100%;

    &.property-file-preview {
        justify-content: flex-start;
    }

    .no-preview {
        align-items: center;
        display: flex;
        justify-content: center;
        h6 {
            font-size: 0.8rem;
        }
    }

    .preview-body {
        overflow: auto;
        position: relative;
        width: 100%;
    }
    .preview-footer {
        border-top: 1px solid $gray-300;
        display: flex;
        justify-content: flex-start;
        padding: 1rem;
        width: 100%;

        > div {
            display: flex;
            flex-direction: column;
            margin: 0 1rem;
        }
    }

    .img {
        border: 1px solid #ddd;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAJ0lEQVQoU2O8e/fufwYkoKSkhMxlYKSDgv///6O44d69e6huoL0CALpMKlF6PO5uAAAAAElFTkSuQmCC);
        max-height: 100%;
        max-width: 100%;
    }
    audio,
    video {
        align-items: center;
        display: flex;
        max-height: 100%;
        outline: none;
        position: absolute;
        width: calc(100% - 6rem);
    }
    audio {
        max-width: 500px;
    }
    iframe {
        border: none;
        height: 100%;
        width: 100%;
    }
    video {
        padding: 1rem;
    }
}
